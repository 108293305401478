<template>
  <form ref="profile_form" @submit.prevent="handleSubmit">
    <!-- Organization -->
    <base-input
      :label="`${$t('COMMON.ORGANIZATION')} (*)`"
      :placeholder="$t('COMMON.ORGANIZATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <organization-selector
        :organization="supplier.organization.id"
        :filterable="true"
        :showAll="false"
        @organizationChanged="
          (organizationId) => {
            supplier.organization.id = organizationId;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.organization" />

    <base-input
      :label="`${$t('COMMON.LOCATION')}`"
      :placeholder="$t('COMMON.LOCATION')"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <locations-selector
        :locations="supplier.allowedLocations"
        :filterable="true"
        :showAll="false"
        :multiple="true"
        :organization="supplier.organization.id"
        @locationsChanged="
          (locations) => {
            supplier.allowedLocations = locations;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.location" />

    <!-- Company name -->
    <base-input
      :label="`${$t('COMMON.COMPANY_NAME')} (*)`"
      v-model="supplier.company_name"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.company_name" />

    <!-- Company name -->
    <base-input
      :label="`${$t('COMMON.CATEGORY')}`"
      v-model="supplier.category"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.category" />

    <!-- Company name -->
    <base-input
      :label="`${$t('COMMON.CUSTOMER_NUMBER')}`"
      v-model="supplier.customer_number"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.customer_number" />

    <!-- Fiscal number -->
    <base-input
      :label="`${$t('COMMON.FISCAL_NUMBER')}`"
      v-model="supplier.fiscal_number"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.fiscal_number" />

    <!-- Email address -->
    <base-input
      :label="`${$t('COMMON.EMAIL')} (*)`"
      v-model="supplier.email"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.email" />

    <!-- Phone number -->
    <div class="row">
      <div class="col-2">
        <base-input :label="`${$t('COMMON.TYPE')}`">
          <el-select
            :label="$t('COMMON.TYPE')"
            :placeholder="$t('COMMON.TYPE')"
            v-model="supplier.phone_type"
            @change="
              (type) => {
                supplier.phone_type = type;
                onFormChanged();
              }
            "
          >
            <el-option
              v-for="(value, key) in phoneTypesOptions"
              :key="key"
              :value="value"
              :label="$t(`COMMON.PHONE_TYPE_${value}`)"
            />
          </el-select>
        </base-input>
      </div>
      <div class="col-7">
        <base-input :label="`${$t('COMMON.PHONE')} (*)`">
          <phone-number-input
            :phoneNumber="supplier.phone"
            @phoneNumberChanged="
              (phone) => {
                supplier.phone = phone;
                onFormChanged();
              }
            "
            :required="true"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.phone" />
      </div>
      <div class="col-3">
        <base-input
          :label="`${$t('COMMON.EXTENSION')}`"
          v-model="supplier.phone_extension"
          @change="
            () => {
              onFormChanged();
            }
          "
          :inputClasses="'extension-input'"
        />
        <validation-error :errors="apiValidationErrors.phone_extension" />
      </div>
    </div>

    <!-- Other phone numbers -->
    <base-input :label="`${$t('COMMON.OTHER_PHONES')}`">
      <phone-numbers-selector
        :phoneNumbers="supplier.other_phones"
        @phoneNumbersChanged="
          (phoneNumbers) => {
            supplier.other_phones = phoneNumbers;
            onFormChanged();
          }
        "
      />
    </base-input>
    <validation-error :errors="apiValidationErrors.other_phones" />

    <div class="form-wrapper full mb-3">
      <addresses-selector
        @addressInputChangeChanged="
          (values) => {
            supplier = {
              ...supplier,
              ...values,
            };
            onFormChanged();
          }
        "
      />
    </div>

    <div class="row">
      <!-- Country -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.COUNTRY')} (*)`"
          :placeholder="$t('COMMON.COUNTRY')"
        >
          <country-selector
            :country="supplier.country"
            :filterable="true"
            :showAll="false"
            @countryChanged="
              (country) => {
                supplier.country = country;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.country" />
      </div>

      <!-- State  -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.STATE')} (*)`"
          :placeholder="$t('COMMON.STATE')"
        >
          <state-selector
            :country="supplier.country"
            :state="supplier.state"
            :filterable="true"
            :showAll="false"
            @stateChanged="
              (state) => {
                supplier.state = state;
                onFormChanged();
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.state" />
      </div>

      <!-- City -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.CITY')} (*)`"
          v-model="supplier.city"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.city" />
      </div>

      <!-- ZIP Code  -->
      <div class="col">
        <base-input
          :label="`${$t('COMMON.ZIPCODE')} (*)`"
          v-model="supplier.zipcode"
          @change="
            () => {
              onFormChanged();
            }
          "
        />
        <validation-error :errors="apiValidationErrors.zipcode" />
      </div>
    </div>

    <!-- Address -->
    <base-input
      :label="`${$t('COMMON.ADDRESS')} (*)`"
      v-model="supplier.address"
      @change="
        () => {
          onFormChanged();
        }
      "
    />
    <validation-error :errors="apiValidationErrors.address" />

    <!-- Tags -->
    <div class="row">
      <div class="col">
        <base-input
          :label="`${$t('COMMON.TAGS')}`"
          :placeholder="`${$t('COMMON.TAGS')}`"
        >
          <tags-selector
            :tags="supplier.tags"
            @tagsChanged="
              (tags) => {
                supplier.tags = tags;
                onFormChanged();
              }
            "
            :disabled="!supplier.organization.id"
            :organization="supplier.organization.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.tags" />
      </div>
    </div>
    <div class="">
      <base-input
        :label="`${$t('COMMON.NOTE')}`"
        :placeholder="$t('COMMON.NOTE')"
      >
        <html-editor v-model="supplier.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>

    <base-checkbox
      v-model="billingAddressSameAsSupplier"
      class="mb-3"
      @input="applySupplierDataToBilling"
    >
      <span class="form-control-label">
        {{ $t("SUPPLIERS.BILLING_INFORMATIONS_SAME_AS_SUPPLIER") }}
      </span>
    </base-checkbox>

    <billing-informations-form
      v-if="!billingAddressSameAsSupplier"
      :billingInformationsData="supplier"
      :formErrors="formErrors"
      :type="supplier.supplier_type"
      @onChangeBillingInformations="billingInformationsChanged"
    />

    <div class="my-4">
      <base-button
        type="button"
        class="btn btn-sm btn-primary"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          supplier.id
            ? $t("SUPPLIERS.EDIT_SUPPLIER")
            : $t("SUPPLIERS.ADD_SUPPLIER")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import { BILLING_ENTITY_TYPE_COMPANY } from "@/constants/billingInformations";
import { phoneTypesOptions } from "@/constants/common";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import CountrySelector from "@/components/CountrySelector.vue";
import TagsSelector from "@/components/TagsSelector.vue";
import StateSelector from "@/components/StateSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import BillingInformationsForm from "@/components/BillingInformationsForm.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import PhoneNumbersSelector from "@/components/PhoneNumbersSelector.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import AddressesSelector from "@/components/AddressesSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    ValidationError,
    CountrySelector,
    HtmlEditor,
    StateSelector,
    OrganizationSelector,
    PhoneNumberInput,
    PhoneNumbersSelector,
    TagsSelector,
    BillingInformationsForm,
    LocationsSelector,
    HtmlEditor,
    AddressesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["supplierData", "formErrors", "loading"],

  data() {
    let supplierData = { ...this.supplierData };
    supplierData = this.$fillUserOrganizationData(supplierData);
    return {
      supplier: supplierData,
      billingAddressSameAsSupplier: false,
      phoneTypesOptions,
    };
  },

  methods: {
    async handleSubmit() {
      let supplierData = cloneDeep(this.supplier);
      supplierData = this.$fillUserOrganizationData(supplierData);
      this.$emit("supplierSubmitted", supplierData);
    },

    billingInformationsChanged(billingInformations) {
      this.supplier = { ...this.supplier, ...billingInformations };
      this.onFormChanged();
    },

    onFormChanged() {
      if (this.billingAddressSameAsSupplier) {
        this.supplier.billing_entity_type = BILLING_ENTITY_TYPE_COMPANY;
        this.supplier.billing_company_name = this.supplier.company_name;
        this.supplier.billing_firstname = this.supplier.firstname;
        this.supplier.billing_lastname = this.supplier.lastname;
        this.supplier.billing_country = this.supplier.country;
        this.supplier.billing_state = this.supplier.state;
        this.supplier.billing_city = this.supplier.city;
        this.supplier.billing_zipcode = this.supplier.zipcode;
        this.supplier.billing_address = this.supplier.address;
        this.supplier.billing_email = this.supplier.email;
        this.supplier.billing_firstname = "N/A";
        this.supplier.billing_lastname = "N/A";
      }
      this.$emit("formChanged");
    },

    applySupplierDataToBilling(value) {
      this.onFormChanged();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    supplierData(supplierData) {
      if (supplierData) {
        this.supplier = {
          ...this.supplier,
          ...cloneDeep(supplierData),
        };
      }
    },
  },
};
</script>
