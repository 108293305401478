import { CUSTOMER_TYPE_COMPANY } from "@/constants/customers";
import { PHONE_TYPE_MOBILE } from "@/constants/common";

export default {
  type: "suppliers",
  financial_number: null,
  excerpt: "",
  company_name: null,
  excerpt: "",
  category: null,
  customer_number: null,
  email: null,
  phone: null,
  phone_extension: null,
  phone_type: PHONE_TYPE_MOBILE,
  other_phones: [],
  country: "Canada",
  state: "Quebec",
  city: null,
  zipcode: null,
  address: null,
  relationshipNames: ["organization", "tags", "allowedLocations"],
  organization: {
    type: "organizations",
    id: null,
  },
  tags: [],
  allowedLocations: [],
  syncResults: [],

  billing_entity_type: CUSTOMER_TYPE_COMPANY,
  billing_company_name: null,
  billing_firstname: null,
  billing_lastname: null,
  billing_country: "Canada",
  billing_state: "Quebec",
  billing_city: null,
  billing_zipcode: null,
  billing_address: null,
  billing_email: null,
};
